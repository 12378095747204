<template>
  <sm-editable-list
    :controllerName="controllerName"
    :parentName="parentName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    :isLoading="isLoadingPage"
    showEditButton
    showDeleteButton
    showCreateButton
    @edit="onEdit"
    @create="onCreate"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'TelemarketingQuestionsForScript',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TelemarketingQuestions',
      parentName: 'Script',
      tableCaption: 'Вопросы по телемаркетингу',
      tableHeaders: [
        { text: 'Скрипт', alias: 'script', order: 'script' },
        { text: 'Вопрос', alias: 'question', order: 'question' },
        { alias: 'actions' },
      ],
      isLoadingPage: false,
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      telemarketingProducts: (state) => state.common.telemarketingProducts,
    }),

    id() {
      return +this.$route.params.id;
    },

    scriptName() {
      return this.item?.data?.script ? this.item.data.script : '';
    },

    breadcrumbs() {
      return [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Скрипты телемаркетинга',
          route: { name: 'TelemarketingScripts' },
        },
        {
          text: `Редактирование скрипта телемаркетинга ${this.scriptName}`,
          route: { name: 'TelemarketingScriptEdit', params: { id: this.id } },
        },
        {
          text: 'Вопросы по телемаркетингу',
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const item = this.getItem({ name: 'TelemarketingScripts', id: this.id });
    const telemarketingProducts = this.getCommonList({
      name: 'TelemarketingProducts',
    });

    Promise.all([telemarketingProducts, item]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      getCommonList: 'common/getCommonList',
    }),

    onCreate() {
      const params = {};

      params.previosRouteName = this.$route.name;

      if (this.$route.query?.filter) {
        params.previosRouteQueryFilter = JSON.parse(this.$route.query.filter);
      }

      if (this.$route.query?.table) {
        params.previosRouteQuerySort = JSON.parse(this.$route.query.table);
      }
      this.$router.push({
        name: 'TelemarketingQuestionsForScriptCreate',
        params: {
          ...params,
        },
      });
    },

    onEdit(id) {
      this.$router.push({
        name: 'TelemarketingQuestionsForScriptEdit',
        params: {
          id,
          parentId: this.id,
        },
      });
    },
  },
};
</script>
